import polyglotI18nProvider from 'ra-i18n-polyglot';
import en from 'ra-language-english';
import fi from './lang/fi'; // Oleta, että fi.js on oikea polku suomen kielitiedostoon

const translations = { en, fi }; // lisää 'fi' käännöksiin

export const i18nProvider = polyglotI18nProvider(
    locale => translations[locale],
    'fi', // vaihda oletuskieli suomeksi
    [{ locale: 'en', name: 'English' },  { locale: 'fi', name: 'Suomi' }], // lisää suomi kielivaihtoehtoihin
);

export default i18nProvider;